body {
  margin: 0 !important;
}

.training-bg-image {
  background-color: #cccccc;
  height: 74vh;
  width: 100%;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  opacity: 0.8;
  overflow-y: hidden;
}

/* Centered text */
.centered {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  font-weight: 800;
  color: rgb(83, 77, 83);
  font-size: 2.5rem;
  font-family: "Helvetica Neue" !important;
}

.training-image {
  max-width: 100%;
  max-height: 160px;
  object-fit: cover;
  height: auto;
}

.training-div {
  text-align: center;
  color: rgba(90, 88, 88, 0.945);
  text-transform: uppercase;
  font-size: 2rem;
}

.training-div-2 {
  position: relative;
  top: 0rem;
  text-align: center;
  color: rgba(90, 88, 88, 0.945);
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Rubik", "Helvetica Neue", "Helvetica", sans-serif;
}

.training-btn1 {
  border: none;
  outline: none;
  height: 50px;
  width: 25%;
  background-color: rgb(245, 143, 76);
  color: white;
  border-radius: 4px;
  font-weight: bold;
  margin-left: 1rem;
}

.disabled {
  background-color: #ccc;
  color: #888;
  cursor: not-allowed;
}

.training-btn1:hover:not(.disabled) {
  background-color:white  ;
  border: 1px solid;
  color: rgba(90, 88, 88, 0.945);
  cursor: pointer;
}

.settings-header {
  position: absolute;
  width: 1628px;
  font-size: 1.5rem;
  font-family: "Rubik", "Helvetica Neue", "Helvetica", sans-serif;
  font-weight: 500;
  margin-left: 15rem;
  top: 3rem;
}

.fw-bold {
  color: rgba(90, 88, 88, 0.945);
}

.para {
  color: rgba(90, 88, 88, 0.945);
  font-size: 1rem;
  position: relative;
  width: 100vw;
  text-align: center;
}

.form-check-input {
  color: rgba(90, 88, 88, 0.945);
}

/* .dfu {
  background-color: rgb(245, 245, 245);
  position: absolute;
  left: 14rem;
  top: 5rem;
  width: 957px;
  margin-left: 1rem;
} */

.dfu-data-grid {
  height: 100vh;
  margin-top: 2rem;
  width: 80vw;
}

.dfu-data-grid--no-data {
  height: 0vh;
  margin-top: 2rem;
  width: 80vw;
}

.para-result {
  color: rgba(90, 88, 88, 0.945);
  font-size: 1rem;
  position: relative;
  left: 2rem;
  width: 100vw;
  text-align: left;
  margin-top: 1rem;
}

.training-div-quiz {
  position: relative;
  top: 0rem;
  width: 100vw;
  text-align: center;
  color: rgba(90, 88, 88, 0.945);
  text-transform: uppercase;
  font-size: 2rem;
}

.training-div-new {
  position: relative;
  left: 2rem;
  width: 70vw;
  text-align: center;
  font-size: 1.5rem;
  color: hsl(220, 10%, 40%);
  font-family: "Rubik", "Helvetica Neue", "Helvetica", sans-serif !important;
}

.exam-label {
  color: rgb(83, 77, 83);
  font-size: 1.2rem;
}

.training_form_select1 {
  border-radius: 4px !important;
  border-color: rgb(196, 194, 194) !important;
  width: 14rem !important;
  height: 48px !important;
  font-size: 0.9em;
  font-family: inherit;
  margin-left: 30px;
}

@media (max-width: 1000px) {
  /* .dfu {
    background-color: rgb(245, 245, 245);
    position: relative;
    left: 14rem;
    top: 5rem;
    width: 60%;
    margin-left: 1rem;
  } */
  .les_faq-row-wrapper__3v {
    padding: 0 !important;
  }

  .training_table_dfu,
  .training_table_dfu tbody,
  .training_table_dfu tr,
  .training_table_dfu td {
    display: block;
    width: 100%;
    padding-bottom: 1rem;
  }

  .training_table_dfu {
    bottom: 6rem;
  }

  .training_table_dfu td {
    text-align: left;
    position: relative;
    top: 0rem;
    width: 90vw;
    word-wrap: break-word;
    min-height: 3rem;
  }

  .training_table_dfu td::before {
    content: attr(data-label);
    position: relative;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    margin-right: 1vh;
  }

  .training_table_dfu td,
  .training_table_dfu th {
    padding: 12px 15px;
    border: 2px solid #ddd;
    text-align: centre;
    font-size: 14px;
  }
}
.questionBox {
  margin-left: 2rem;
}
.quiz-submit-button {
  display: flex;
  justify-content: center;
  padding: 1rem;
}
@media only screen and (max-width: 1000px) {
  .training-div {
    font-weight: 600;
    left: 9rem;
    margin-bottom: 0rem;
  }

  .training-bg-image {
    background-color: #cccccc;
    height: auto;
    width: 95%;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    opacity: 0.8;
    overflow-y: hidden;
  }

  .centered {
    top: 30%;
  }

  .training-div-quiz {
    text-align: left;
    font-weight: 600;
    margin-left: -3rem;
    margin-bottom: 1rem;
  }

  .exam-label {
    margin-left: 13rem;
  }

  .training-div-2 {
    text-align: left;
  }

  .page_title_span_title_training {
    position: absolute;
    top: 1.4rem;
    left: -3rem;
    width: 100vw;
  }

  .training-btn1 {
    height: 3rem;
    width: 14vw;
    font-weight: bold;
    margin-left: 2rem;
    font-size: 1rem;
  }

  .training_form_select1 {
    margin-top: 1rem !important;
    font-size: 1rem;
    width: 28vw !important;
    margin-left: 2rem;
    height: 4rem !important;
  }

  .page-title-css-training {
    position: absolute;
    left: 0rem;
    width: 100vw !important;
    height: 52px;
    top: 0;
  }

  .question-box {
    margin-left: -1rem;
  }

  .para {
    left: 0rem;
    text-align: left;
    width: 90vw;
  }

  .training-div-new {
    top: 0rem;
    width: 90vw;
    text-align: left;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    left: 0rem;
  }
}

@media only screen and (max-width: 450px) {
  .training-div {
    text-align: center;
    left: 8rem;
  }

  .training-root {
    margin-bottom: 7rem;
  }

  .exam-label {
    margin-left: -21rem;
    font-size: 1.1rem;
  }

  .training-div-2 {
    left: 22rem;
    top: 12rem;
    width: 15vw;
  }

  .page_title_span_title_training {
    top: 0rem;
    left: -7.5rem;
    width: 95vw;
  }

  .training-btn1 {
    height: 3rem;
    width: 100vw;
    font-weight: bold;
    margin-left: -22rem;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .training_form_select1 {
    width: 90vw !important;
    margin-left: 0.4rem;
    margin-bottom: 1rem !important;
  }

  .page-title-css-training {
    position: absolute;
    left: 0rem;
    width: 100vw !important;
    height: 52px;
    top: 0;
  }

  .question-box {
    margin-left: -1rem;
  }

  .para {
    left: 0rem;
    text-align: left;
    width: 90vw;
  }

  .training-div-new {
    top: 0rem;
    width: 90vw;
    text-align: left;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    left: 0rem;
  }
}
