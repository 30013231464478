.field-form-title h2{
  font-size: 1.2rem;
  font-weight: bold;
}

.form-label {
  font-size: 1rem ;
}

.btnWrapper {
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 1rem !important;
}
.submitBtn {
  background-color: #1976d2 !important;
  margin-left: 1rem !important;
}